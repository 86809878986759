<template>
    <client-page>

        <sub-visual
        sh="법인소개"
        tabActive="나눔기업"
        bg="/images/sub/visual/sv1.jpg">
        </sub-visual>

        <section class="section">
            <v-container>
                <v-row class="row-cols-3 row-cols-md-5">
                    <v-col v-for="logo, idx in logos.slides" :key="idx" >
                        <v-img :src="logo?.image" class="w-100 border" />
                    </v-col>
                </v-row>
                <!-- <v-row class="row-cols-3 row-cols-md-5">
                    <v-col>
                        <v-img src="/images/sub/company/partner/logo1.jpg" class="w-100 border" />
                    </v-col>
                    <v-col>
                        <v-img src="/images/sub/company/partner/logo2.jpg" class="w-100 border" />
                    </v-col>
                    <v-col>
                        <v-img src="/images/sub/company/partner/logo3.jpg" class="w-100 border" />
                    </v-col>
                    <v-col>
                        <v-img src="/images/sub/company/partner/logo4.jpg" class="w-100 border" />
                    </v-col>
                    <v-col>
                        <v-img src="/images/sub/company/partner/logo5.jpg" class="w-100 border" />
                    </v-col>
                    <v-col>
                        <v-img src="/images/sub/company/partner/logo6.jpg" class="w-100 border" />
                    </v-col>
                    <v-col>
                        <v-img src="/images/sub/company/partner/logo7.jpg" class="w-100 border" />
                    </v-col>
                    <v-col>
                        <v-img src="/images/sub/company/partner/logo8.jpg" class="w-100 border" />
                    </v-col>
                    <v-col>
                        <v-img src="/images/sub/company/partner/logo9.jpg" class="w-100 border" />
                    </v-col>
                </v-row> -->
            </v-container>
        </section>

    </client-page>
</template>

<script>
import api from "@/api";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
    },
    data() {
        return {
            logos: []
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { banner }= await api.v1.banners.get({ params: { code: 'logo' }});
            this.logos = banner;
        }
    }
};
</script>

<style lang="scss" scoped>
</style>