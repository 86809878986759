var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "법인소개",
      "tabActive": "나눔기업",
      "bg": "/images/sub/visual/sv1.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row-cols-3 row-cols-md-5"
  }, _vm._l(_vm.logos.slides, function (logo, idx) {
    return _c('v-col', {
      key: idx
    }, [_c('v-img', {
      staticClass: "w-100 border",
      attrs: {
        "src": logo === null || logo === void 0 ? void 0 : logo.image
      }
    })], 1);
  }), 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }